<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/product/activity' }">活动列表</el-breadcrumb-item>
                <el-breadcrumb-item>活动详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div style="height: 20px;"></div>
        <!--内容-->
        <div class="page-content-x">
            <!--标题-->
            <el-row>
                <el-col :span="12">
                    <h3>活动详情</h3>
                </el-col>
            </el-row>
            <!--详情-->
            <el-row>
                <el-col :span="16">
                    <el-form v-loading="loading" size="medium" ref="form" :model="form" :label-width="this.env.label_width">
                        <el-form-item label="活动名称">
                            <span class="form-span-text">{{ form.activity_title }}</span>
                        </el-form-item>
                        <el-form-item label="运动品类">
                            <div class="form-tag_colour-div">
                                <el-tag :key="item.index" v-for="item in form.motion_category_two"
                                    class="form-tag_colour-tag">
                                    {{ item.name }}
                                </el-tag>
                            </div>
                        </el-form-item>
                        <el-form-item label="运动效果">
                            <div class="form-tag_colour-div">
                                <el-tag :key="item.index" v-for="item in form.target_category_zero"
                                    class="form-tag_colour-tag">
                                    {{ item.name }}
                                </el-tag>
                            </div>
                        </el-form-item>
                        <el-form-item label="活动形式">
                            <span class="form-span-text">{{ form.activity_type === 1 ? '线下活动' : '线上活动' }}</span>
                        </el-form-item>

                        <div class="appoint" v-show="form.activity_type === 1">
                            <el-form-item label="活动地区" prop="county">
                                <span class="form-span-text">{{ `${form.province}${form.city}${form.county}` }}</span>
                            </el-form-item>
                            <el-form-item label="详细地址" prop="address">
                                <span class="form-span-text">{{ form.address }}</span>
                            </el-form-item>
                        </div>
                        <el-form-item label="联系方式">
                            <span class="form-span-text">{{ form.activity_phone }}</span>
                        </el-form-item>
                        <el-form-item label="指定门店" prop="activity_store">
                            <span class="form-span-text">{{ form.store_uuid_all.length > 0 ?
                                form.store_uuid_all[0].store_name : '' }}</span>
                        </el-form-item>
                        <el-form-item v-if="returnSkuState()" label="规格信息">
                            <section class="specs__table">
                                <div class="specs__table-header">
                                    <section class="specs__table-title">
                                        <p>规格标题</p>
                                    </section>
                                    <section class="specs__table-title">
                                        <p>价格（元）</p>
                                    </section>
                                    <section class="specs__table-title">
                                        <p>人数上限</p>
                                    </section>
                                </div>
                                <div class="specs__table-content">
                                    <section v-for="(item, index) in sku_data" :key="index">
                                        <div v-if="item.sku_name && item.sku_state === 1" class="specs__table-cell">
                                            <div class="specs__table-title">
                                                <p>{{ item.sku_name }}</p>
                                            </div>
                                            <div class="specs__table-title">
                                                <span class="form-span-text">{{ item.sku_price }}</span>
                                            </div>
                                            <div class="specs__table-title">
                                                <span class="form-span-text">{{ item.sku_qty }}</span>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </section>
                        </el-form-item>
                        <el-form-item label="人数限制" prop="quota">
                            <span class="form-span-text">{{ form.quota }}</span>
                        </el-form-item>

                        <el-form-item label="报名时间" prop="signup_end_time">
                            <span class="form-span-text">{{ `${form.signup_start_time} - ${form.signup_end_time}` }}</span>
                        </el-form-item>
                        <el-form-item label="活动时间">
                            <span class="form-span-text">{{ form.time_type === 1 || form.time_type === 2 ? '固定时间' : '持续时间'
                            }}</span>
                            <template v-if="form.time_type === 1 || form.time_type === 2">
                                <div v-for="(item, index) in this.form.detailed_time" :key="index" style="display: flex;">
                                    <span style="width: 60px;">第{{ index + 1 }}天：</span>
                                    <div>
                                        <p style="margin: 0;" class="form-span-text">{{ item.activity_date }}</p>
                                        <p style="margin: 0;" class="form-span-text">{{ `${item.activity_time[0]} -
                                                                                    ${item.activity_time[1]}` }}
                                        </p>
                                    </div>
                                </div>
                            </template>
                            <div v-else>
                                <span class="form-span-text">{{ `${form.start_time} - ${form.end_time}` }}</span>
                            </div>
                        </el-form-item>
                        <el-form-item label="活动视频">
                            <Qnupload v-model="form.video" type="video" :readonly='true' />
                        </el-form-item>
                        <el-form-item label="活动封面" prop="cover">
                            <Qnupload v-model="form.cover" :isclipper="true" :compress="false" :fixedNumber="[1, 1]"
                                :readonly='true' />
                        </el-form-item>
                        <el-form-item label="活动照片" prop="photo">
                            <Qnupload v-model="form.photo" :sum="5" :isclipper="true" :compress="false" :readonly='true'
                                :fixedNumber="[25, 28]" />
                        </el-form-item>

                        <el-form-item label="活动价格" prop="originally_price">￥
                            <span v-if="returnSkuState()" class="form-span-text">{{ minPrice }}</span>
                            <span v-else class="form-span-text">{{ form.originally_price }}</span>
                        </el-form-item>
                        <el-form-item v-if="!returnSkuState()" label="优惠价格">
                            <span class="form-span-text">￥{{ form.promotion_price }}</span>
                        </el-form-item>
                        <el-form-item label="退款规则" prop="is_refund">
                            <span class="form-span-text">{{ form.is_refund === 0 ? '不允许退款' : '允许退款' }}</span>
                            <p class="form-span-text" v-if="form.is_refund === 1">
                                <span>{{ form.is_refund_end_time }}</span> <span style="margin-left: 10px;">前可退款</span>
                            </p>
                        </el-form-item>

                        <el-form-item label="活动简介" prop="introduce">
                            <span class="form-span-text">{{ form.introduce }}</span>
                        </el-form-item>
                        <el-form-item label="注意事项" prop="attention">
                            <span class="form-span-text">{{ form.attention }}</span>
                        </el-form-item>
                        <el-form-item label="详情">
                            <!-- 富文本编辑框 -->
                            <div v-html="form.details_rich_text" class="rich-text__container" />
                        </el-form-item>
                        <el-form-item>
                            <el-button size="medium" @click="isreturn">返回
                            </el-button>
                        </el-form-item>

                    </el-form>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
import Qnupload from '@/components/Qnupload'
export default {
    components: {
        Qnupload,
    },
    data() {
        return {
            activity_uuid: '',              // 活动uuid
            store_optionals: [],            // 可选门店信息
            brand_optionals: [], // 可选品牌信息
            select_store_data: null,        // 选中的门店数据
            chooseBrandId: [],

            select_tag: '',
            tag_list: [],
            loading: true,

            signup_time: '',                // 报名时间
            activity_time: '',              // 活动时间
            activity_time_type: 1,
            // 规格信息
            sku_data: [],
            // 规格类型 0--> 无规格 1--> 多规格
            sku_type: 0,
            minPrice: 0,
            // 表单内容
            form: {
                store_uuid_all: []
            },
        }
    },
    // 创建
    created() {
        this.loading = false
        this.init()
    },
    methods: {
        // 判断是否为空
        is_empty(auth) {
            return this.Tool.is_empty(auth)
        },
        // 初始化
        init() {
            let activity_uuid = this.$route.query.activity_uuid
            if (!this.Tool.is_empty(activity_uuid)) {
                this.activity_uuid = activity_uuid
                this.getinfo(activity_uuid)
            } else {
                this.loading = false
            }
        },
        //获取详情
        getinfo(activity_uuid) {
            let postdata = {
                api_name: "product.activity.getinfo",
                token: this.Tool.get_l_cache('token'),
                activity_uuid
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                // console.log('json', json)
                if (json.code === 0) {
                    if (json.data.brand_uuid) {
                        this.chooseBrandId = [json.data.brand_uuid];
                    }
                    if (json.data.sku_data) {
                        if (json.data.sku_data.length > 0) {
                            this.sku_data = json.data.sku_data;
                            this.mathMin();
                        }
                    }
                    this.form = json.data
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 返回
        isreturn() {
            this.$router.push({ path: '/product/activity' })
        },
        // 计算规格中的最小价格
        mathMin() {
            const { sku_data } = this;
            let data = [], priceData = [];
            sku_data.map(item => {
                if (item.sku_state !== 0) {
                    data = data.concat(item);
                }
            });
            data.map(item => {
                if (!this.Tool.is_empty(item.sku_price)) {
                    priceData = priceData.concat(parseFloat(item.sku_price));
                }
            })
            if (priceData.length > 0) {
                const price = Math.min(...priceData);
                this.minPrice = price;
            }
        },
        // 返回是否有设置规格
        returnSkuState() {
            const { sku_data } = this;
            let count = 0;
            if (sku_data.length === 0) {
                return false;
            } else {
                for (let i in sku_data) {
                    if (sku_data[i].sku_state === 0) {
                        count += 1;
                    }
                }
                if (count === sku_data.length) {
                    return false;
                } else {
                    return true;
                }
            }
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  scoped>
>>>p {
    margin: 0;
}
</style>
